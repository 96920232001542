import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import styled, { css } from 'styled-components'

import { Link, LinkProps } from 'components/Link/Link'
import { Category } from 'data/categories'
import { Box, BoxProps, Text, TextProps } from 'ui/common'

interface CategoryCardProps extends Omit<Category, 'slug' | 'imageName'> {
  to: string
  gatsbyImageData: IGatsbyImageData | undefined
}

const getLetterSpacing = (word: string, titleType: 'main' | 'secondary') => {
  const isSecondary = titleType === 'secondary'
  const long = isSecondary ? 12 : 9
  const mid = isSecondary ? 10 : 7
  if (word.length > long) return '0px'
  if (word.length > mid && !isSecondary) return '2px'
  return isSecondary ? '2px' : '5px'
}

const calculateOrder = (order: number, screenWidth: 'mobileL' | 'monitorS') => {
  if (screenWidth === 'monitorS') {
    // move 3rd element 1 place earlier (subtract 2)
    if (order === 3) return 1
    // move 7th element 2 places earlier (subtract 3)
    if (order === 7) return 4
  }
  if (screenWidth === 'mobileL') {
    // move 4th element 1 place earlier (subtract 2)
    if (order === 4) return 2
    // move 6th element 1 place earlier (subtract 2)
    if (order === 6) return 4
    // move 8th element 1 place earlier (subtract 2)
    if (order === 8) return 6
  }
  return order
}

const CategoryCard: React.FC<CategoryCardProps> = ({
  to,
  titleMain,
  titleSecondary,
  gatsbyImageData,
  imageAlt,
  order,
}) => {
  return (
    <CardWrapper
      forwardedAs={Link}
      to={to}
      order={{
        _: order,
        mobileL: calculateOrder(order, 'mobileL'),
        monitorS: calculateOrder(order, 'monitorS'),
        monitor: order,
      }}
    >
      {gatsbyImageData && (
        <Box height="100%" width="100%">
          <GatsbyImage
            image={gatsbyImageData}
            alt={imageAlt}
            style={{
              objectFit: 'cover',
              objectPosition: '50% 50%',
              height: '100%',
              width: '100%',
            }}
          />
        </Box>
      )}
      <CardOverlayGradient />
      <CardHeaderWrapper forwardedAs="header">
        {titleMain && (
          <TitleMain
            forwardedAs="h1"
            fontSize={{ _: 'm', mobile: 'l', tablet: 'xxl' }}
            fontWeight={{ tablet: 'extraBold' }}
            textColor="gray200"
            letterSpacing={getLetterSpacing(titleMain, 'main')}
          >
            {titleMain}
            {titleSecondary && (
              <TitleSecondary
                forwardedAs="span"
                fontSize={{ _: 's', mobile: 'm', tablet: 'xl' }}
                textColor="accent"
                letterSpacing={getLetterSpacing(titleSecondary, 'secondary')}
              >
                {titleSecondary}
              </TitleSecondary>
            )}
          </TitleMain>
        )}
      </CardHeaderWrapper>
    </CardWrapper>
  )
}

export default CategoryCard

const CardWrapper = styled(Box)<BoxProps & LinkProps>`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transition: box-shadow 0.2s ease-in;
  user-select: none;

  &:hover,
  &:active,
  &:focus {
    box-shadow: 2px 2px 8px 2px ${p => p.theme.colors.gray600};
  }

  @media ${p => p.theme.devices.tablet.min} {
    :nth-child(1),
    :nth-child(5) {
      grid-row: span 2;
    }

    :nth-child(3),
    :nth-child(7) {
      grid-column: span 2;
    }
  }
`

const CardOverlayGradient = styled(Box)`
  position: absolute;
  height: 60%;
  left: 0px;
  right: 0px;
  bottom: 0px;

  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.55) 55%,
    rgba(0, 0, 0, 0.6) 100%
  );
`

const CardHeaderWrapper = styled(Box)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: calc(8px + 0.75rem);
  font-size: 2rem;
  z-index: 2;
`

const commonTextStyles = css`
  text-transform: uppercase;
  text-align: left;
`

const TitleMain = styled(Text)<TextProps>`
  ${commonTextStyles}
`

const TitleSecondary = styled(Text)<TextProps>`
  ${commonTextStyles}
  display: block;
`
