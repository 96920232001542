import { graphql, useStaticQuery } from 'gatsby'
import styled from 'styled-components'

import { File } from 'types/gatsby'
import CategoryCard from 'ui/CategoryCard/CategoryCard'
import { Grid, GridProps } from 'ui/common'
import { CategoriesGridProps } from './types'

export const CategoriesGridDesktop: React.FC<CategoriesGridProps> = ({
  categories,
}) => {
  const categoriesImages = useStaticQuery<Record<string, File>>(imagesQuery)

  return (
    <CategoriesGrid
      forwardedAs="section"
      marginX={{ _: 0, mobileL: 'm', tablet: 'auto' }}
      gridTemplateColumns={{
        _: '1fr',
        mobileL: '1fr 1fr',
        tablet: 'repeat(2, 280px)',
        monitorS: 'repeat(3, 280px)',
        monitor: 'repeat(4, 280px)',
      }}
    >
      {categories.map(category => {
        const { slug, imageName, ...categoryRest } = category
        // check if all categories have corresponding image file available
        if (
          process.env.NODE_ENV === 'development' &&
          !categoriesImages[imageName]
        ) {
          throw new Error(
            `Please add a correct query for a file: '${imageName}', category: '${
              category.titleMain
            }${category.titleSecondary ? ` ${category.titleSecondary}` : ''}'.`
          )
        }

        const gatsbyImageData =
          categoriesImages[imageName].childImageSharp?.gatsbyImageData

        return (
          <CategoryCard
            to={`/dekoracje/${slug}`}
            gatsbyImageData={gatsbyImageData ?? undefined}
            key={slug}
            {...categoryRest}
          />
        )
      })}
    </CategoriesGrid>
  )
}

export const CategoriesGrid = styled(Grid)<GridProps>`
  grid-auto-rows: 280px;
  grid-gap: 1.2rem;
  justify-content: center;
  justify-items: center;
  align-items: center;
`

const imagesQuery = graphql`
  query CategoriesImagesQuery {
    wazonyStojaki: file(relativePath: { eq: "categories/wazony-stojaki.jpg" }) {
      ...categoryImage
    }
    ramkiNumeryStolow: file(
      relativePath: { eq: "categories/ramki-numery-stołów.jpg" }
    ) {
      ...categoryImage
    }
    swiecznikiLampiony: file(
      relativePath: { eq: "categories/świeczniki-lampiony.jpg" }
    ) {
      ...categoryImage
    }
    drewnianeAkcesoria: file(
      relativePath: { eq: "categories/drewniane-akcesoria.jpg" }
    ) {
      ...categoryImage
    }
    bramkiStelaze: file(relativePath: { eq: "categories/bramki-stelaże.jpg" }) {
      ...categoryImage
    }
    podtalerze: file(relativePath: { eq: "categories/podtalerze.jpg" }) {
      ...categoryImage
    }
    tkaniny: file(relativePath: { eq: "categories/tkaniny.jpg" }) {
      ...categoryImage
    }
    oswietlenieDekoracyjne: file(
      relativePath: { eq: "categories/oświetlenie-dekoracyjne.jpg" }
    ) {
      ...categoryImage
    }
  }
`
