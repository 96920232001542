import { useIsMobile } from 'hooks/useIsMobile'
import { Box } from 'ui/common'
import SectionTitle from 'ui/Section/SectionTitle'
import { CategoriesGridDesktop } from './CategoriesGridDesktop'
import { CategoriesGridMobile } from './CategoriesGridMobile'
import { CategoriesGridProps } from './types'

interface CategoriesListProps extends CategoriesGridProps {}

const CategoriesList: React.FC<CategoriesListProps> = ({ categories }) => {
  const isMobile = useIsMobile()

  return (
    <Box paddingTop="xl">
      <SectionTitle title="Katalog produktów" uppercase />
      {isMobile ? (
        <CategoriesGridMobile categories={categories} />
      ) : (
        <CategoriesGridDesktop categories={categories} />
      )}
    </Box>
  )
}

export default CategoriesList
