import { graphql, navigate, useStaticQuery } from 'gatsby'
import get from 'lodash/get'

import CategoriesList from 'components/CategoriesList/CategoriesList'
import { Hero } from 'components/Hero/Hero'
import { IndexPageCtaButton } from 'components/IndexPageCtaButton/IndexPageCtaButton'
import Layout from 'components/Layout/Layout'
import { PageSection } from 'components/PageSection/PageSection'
import SEO from 'components/SEO/SEO'
import { categories } from 'data/categories'
import { Box, Flex, Text } from 'ui/common'

const DecorsRentalIndex: React.FC = () => {
  const data = useStaticQuery(graphql`
    query IndexQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const siteTitle = get(data, 'site.siteMetadata.title')

  return (
    <Layout>
      <SEO title={siteTitle} />
      <Hero />

      {/* Motto */}
      <PageSection
        maxWidth={{
          _: '90%',
          mobileL: '80%',
          tablet: '700px',
          monitorS: '780px',
        }}
        py={{ _: 'xxxl', tablet: '5xl' }}
        backgroundColor="gray200"
      >
        <Text
          fontFamily="montserrat"
          fontSize={{ _: 'm', mobileL: 'l', tablet: 'xl' }}
          lineHeight={{ _: 'm', tablet: 'l' }}
          textAlign="center"
        >
          Nasza wypożyczalnia to miejsce, w którym znajdziesz wiele dodatków i
          dekoracji, dzięki którym uświetnisz swoje&nbsp;przyjęcie.
          <br /> Sprawdź, na pewno jest w niej coś dla Ciebie.
        </Text>
      </PageSection>

      <Box mb="4xl">
        <CategoriesList categories={categories} />
      </Box>

      {/* How to order - CTA */}
      <PageSection
        maxWidth={{
          _: '90%',
          mobileL: '80%',
          monitor: '960px',
        }}
        py={{ _: 'xxxl', tablet: '5xl' }}
        mb="-64px" // footer's mt
        backgroundColor="gray200"
      >
        <Flex
          flexWrap="wrap"
          flexDirection={{ _: 'column', mobileL: 'row' }}
          mb="s"
        >
          <Box
            flexBasis={{ _: 100, mobileL: 200 }}
            flexGrow={1}
            flexShrink={0}
            mr={{ _: 'none', mobileL: '4xl' }}
            mb={{ _: 'l', tablet: 'none' }}
          >
            <Text
              fontFamily="montserrat"
              fontSize={{ _: 'm', mobileL: 'l', tablet: 'xl' }}
              lineHeight={{ _: 'm', tablet: 'l' }}
              mb="xs"
            >
              Chcesz wypożyczyć dekoracje?
            </Text>
            <Text
              fontFamily="montserrat"
              fontSize={{ _: 's', mobileL: 'm', tablet: 'l' }}
              lineHeight={{ _: 'm', tablet: 'l' }}
            >
              {/* todo: Dodaj wybrane produkty do Listy Życzeń, wypełnij formularz
              kontaktowy, a my poinformujemy Cię o dostępności oraz opcjach
              dostawy. */}
              Wypełnij formularz kontaktowy, podając w nim, które produkty Cię
              interesują, a my poinformujemy Cię o dostępności oraz opcjach
              dostawy.
            </Text>
          </Box>
          <Flex flexDirection="column" flexShrink={0}>
            <IndexPageCtaButton
              variant="solid"
              mb="m"
              stretched
              onClick={() => {
                navigate('/jak-dzialamy')
              }}
            >
              Jak działamy
            </IndexPageCtaButton>
            <IndexPageCtaButton
              variantBgColor="white"
              stretched
              onClick={() => {
                navigate('/kontakt')
              }}
            >
              Kontakt
            </IndexPageCtaButton>
          </Flex>
        </Flex>
      </PageSection>
    </Layout>
  )
}

export default DecorsRentalIndex
