import { Button, ButtonProps } from 'ui/common'

// Responsive button for Hero header - 4 sizes
export const HeroCtaButton: React.FC<ButtonProps> = props => (
  <Button
    variant="solid"
    inverted
    fontSize={{
      _: 's',
      mobileL: 'm',
      tablet: 'l',
      monitor: 'xl',
    }}
    lineHeight={{
      _: 's',
      mobileL: 's',
      tablet: 'm',
      monitor: 'm',
    }}
    px={{
      _: 'm',
      mobileL: 'l',
      tablet: 'xl',
      monitor: 'xl',
    }}
    {...props}
  />
)
