import styled from 'styled-components'
import {
  Box,
  BoxProps,
  CustomThemeSpacesProps,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from 'ui/common'

const Z_INDEX_BACKGROUND_IMAGE = -2
const Z_INDEX_BACKGROUND_OVERLAY = -1

interface PageSectionProps
  extends Pick<BoxProps, keyof CustomThemeSpacesProps | 'maxWidth'>,
    StyledSectionProps {
  title?: string
  titleAlign?: TextProps['textAlign']
  color?: BoxProps['color']
  /** Note: Overrides `backgroundImg` and `backgroundOverlay` */
  backgroundColor?: BoxProps['backgroundColor']
  backgroundImg?: JSX.Element
  backgroundOverlay?: BoxProps['color']
}

export const PageSection: React.FC<PageSectionProps> = ({
  maxWidth,
  maxHeight,
  minHeight = '200px',
  color,
  backgroundColor,
  title,
  titleAlign,
  backgroundImg,
  backgroundOverlay,
  children,
  ...rest
}) => (
  <StyledSection
    forwardedAs="section"
    justifyContent="center"
    alignItems="center"
    maxHeight={maxHeight}
    minHeight={minHeight}
    color={color}
    backgroundColor={backgroundColor}
    {...rest}
  >
    <Flex
      maxWidth={maxWidth}
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      {backgroundImg ? <Background>{backgroundImg}</Background> : null}
      {backgroundOverlay ? (
        <BackgroundOverlay backgroundColor={backgroundOverlay} />
      ) : null}
      {title ? (
        <Text as="h2" textAlign={titleAlign} mb="xs">
          {title}
        </Text>
      ) : null}
      {children}
    </Flex>
  </StyledSection>
)

interface StyledSectionProps extends FlexProps {
  maxHeight?: FlexProps['maxHeight']
  minHeight?: FlexProps['minHeight']
}

const StyledSection = styled(Flex)<StyledSectionProps>`
  position: relative;
  margin-left: auto;
  margin-right: auto;
`

const Background = styled(Box)`
  position: absolute;
  z-index: ${Z_INDEX_BACKGROUND_IMAGE};
  top: 0;
  left: 0;
  min-width: 100%;
  height: 100%;
  overflow: hidden;

  .gatsby-image-wrapper {
    height: 100%;
  }
`

const BackgroundOverlay = styled(Box)`
  position: absolute;
  z-index: ${Z_INDEX_BACKGROUND_OVERLAY};
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  opacity: 0.6;
`
