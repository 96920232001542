import { graphql, navigate, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from 'styled-components'

import { MAIN_HEADER_HEIGHT } from 'constants/dimensions'
import { defaultCategory } from 'data/categories'
import { File } from 'types/gatsby'
import { Box, Text } from 'ui/common'
import { HeroCtaButton } from './HeroCtaButton'

export const Hero: React.FC = () => {
  const heroImageQuery = useStaticQuery<Record<string, File>>(imagesQuery)
  const gatsbyImageData =
    heroImageQuery.heroImage.childImageSharp?.gatsbyImageData

  return (
    <HeroWrapper display="flex" alignItems="center">
      {gatsbyImageData && (
        <GatsbyImage
          image={gatsbyImageData}
          alt={''}
          style={{
            objectFit: 'cover',
            objectPosition: '50% 50%',
            height: '100%',
            width: '100%',
          }}
        />
      )}
      <Box
        position="absolute"
        bottom="12%"
        left={{ _: 0, tablet: 90, monitor: 120 }}
        width={{ _: '100%', tablet: 'unset' }}
      >
        <Box mx={{ _: 'auto', tablet: 0 }} width="fit-content">
          <HeroText
            fontFamily="montserrat"
            fontSize={{
              _: 'xxl',
              mobileL: '40px',
              tablet: 'xxxl',
              monitor: 'xxxxl',
            }}
            fontWeight="extraBold"
            color="white"
          >
            WYPOŻYCZALNIA
            <br />
            DEKORACJI
          </HeroText>
          <HeroCtaButton
            my="s"
            onClick={() => {
              navigate(`/dekoracje/${defaultCategory.slug}`)
            }}
          >
            Katalog
          </HeroCtaButton>
        </Box>
      </Box>
    </HeroWrapper>
  )
}

const HeroWrapper = styled(Box)`
  height: calc(100vh - ${MAIN_HEADER_HEIGHT}px);
  background: linear-gradient(
    45deg,
    ${p => p.theme.colors.primary} 0%,
    ${p => p.theme.colors.accent} 100%
  );
  user-select: none;
`

const HeroText = styled(Text)`
  text-transform: uppercase;
  letter-spacing: -0.045em;
  line-height: ${p => p.theme.lineHeights.none};
  word-break: break-all;
`

const imagesQuery = graphql`
  query HeroImagesQuery {
    heroImage: file(
      relativePath: { eq: "glamo-hero-para-siedzi-na-schodach.jpg" }
    ) {
      ...heroImage
    }
  }
`
