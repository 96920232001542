import { Button, ButtonProps } from 'ui/common'

// Responsive button for index (main) page - 3 sizes
export const IndexPageCtaButton: React.FC<ButtonProps> = props => (
  <Button
    fontSize={{
      _: 's',
      mobileL: 'm',
      tablet: 'l',
    }}
    lineHeight={{
      _: 's',
      mobileL: 's',
      tablet: 'm',
    }}
    px={{
      _: 'm',
      mobileL: 'l',
      tablet: 'xl',
    }}
    {...props}
  />
)
